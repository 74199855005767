import React, { Fragment } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Image, Box } from 'rebass';
import { SectionLink } from 'react-scroll-section';
import styled from 'styled-components';
import MouseIcon from '../MouseIcon';
import { Hero, Subheading } from '../ProjectHeading';

const Banner = styled(Image)`
  margin-top: 190px;
  margin-bottom: -170px;

  @media only screen and (max-width: 700px) {
    margin-bottom: -120px;
  }
`;

const HeaderBox = styled(Box)`
  position: absolute;
  font-style: italic;

  transform: rotate(-10deg);

  @media only screen and (max-width: 700px) {
  }
`;

const DesignThinkingIntro = () => (
  <StaticQuery
    query={graphql`
      query DesignThinkingIntro {
        contentfulDesignThinkingOutOfTheBox {
          title
          subtitle
          banner {
            file {
              url
            }
          }
        }
      }
    `}
    render={({ contentfulDesignThinkingOutOfTheBox }) => {
      return (
        <Fragment>
          <HeaderBox pt={[5, 80, 120]} px={[4, 4, 4]}>
            <Hero
              as="h1"
              color="totalDark"
              fontSize={[5, 6, 8]}
              mb={[2, 3, 4]}
            >
              {contentfulDesignThinkingOutOfTheBox.title}
            </Hero>

            <Subheading as="h2" color="totalDark" fontSize={[4, 5, 6]} mb={[3, 5]}>
              {contentfulDesignThinkingOutOfTheBox.subtitle}
            </Subheading>
          </HeaderBox>

          <div>
            <Banner
              src={contentfulDesignThinkingOutOfTheBox.banner.file.url}
              width="97%"
              alt="Design Thinking Set"
            />
          </div>

          <SectionLink section="Design Thinking Content">
            {({ onClick }) => <MouseIcon onClick={onClick} />}
          </SectionLink>
        </Fragment>
      );
    }}
  />
);

export default DesignThinkingIntro;
