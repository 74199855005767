import React from 'react';
import Layout from '../../components/Layout';
import ProjectHeader from '../../components/ProjectHeader';
import DesignThinkingIntro from '../../components/DesignThinking/DesignThinkingIntro'
import DesignThinkingContent from '../../components/DesignThinking/DesignThinkingContent'
import Footer from '../../components/Footer';

const IndexPage = () => (
  <Layout>
    <ProjectHeader />
    <DesignThinkingIntro />
    <DesignThinkingContent />
    <Footer />
  </Layout>
);

export default IndexPage;
